<template>
  <ion-page>
    <ion-header collapse="fade" class="ion-no-border">
      <nav-bar-mobile v-if="$isHybridPlatform"></nav-bar-mobile>
    </ion-header>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu v-if="isAuth && !$isHybridPlatform"></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar v-if="isAuth && !$isHybridPlatform"></nav-bar>
        <slot></slot>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonHeader, IonPage} from "@ionic/vue";
import NavBarMobile from "@core/layouts/app-nav-bar/mobile/NavBarMobile";
import VerticalNavMenu from "@core/layouts/vertical-nav-menu/VerticalNavMenu";
import {useStore} from "vuex";
import {computed} from "vue";
import NavBar from "@core/layouts/app-nav-bar/NavBar";

export default {
  name: "PageTemplate",
  components:{NavBar, VerticalNavMenu, IonContent, IonHeader, IonPage, NavBarMobile},
  setup(){
    const store = useStore()
    const isAuth = computed(()=>{
      return store.state.auth.is_logged_in
    })
    return{
      isAuth,
    }
  }
}
</script>
