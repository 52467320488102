<template>
  <page-template>
    <main-block>
      <div class="content-page wide-md m-auto">
        <div class="bg-white bg-white p-4 rounded mb-3 d-flex justify-between" v-if="!$isHybridPlatform">
          <brand-logo></brand-logo>
          <nk-button type="primary" v-if="ionRouter.canGoBack()" v-on:click="$router.go(-1)">{{ $t('Back') }}</nk-button>
        </div>
        <div class="nk-block-head nk-block-head-lg wide-sm mx-auto">
          <div class="nk-block-head-content text-center">
            <h2 class="nk-block-title fw-normal">Algemene Voorwaarden Leadafname We/Assist Hypotheekspecialisten</h2>
            <div class="nk-block-des">
              <p class="lead">Deze algemene voorwaarden zijn van toepassing op de overeenkomst inzake de levering van lead- en afspraakgegevens door We/Assist Hypotheekspecialisten.</p>
              <p class="text-soft ff-italic">{{ $t('Last Update') }}: Jan 26, 2022</p>
            </div>
          </div>
        </div>
        <block-content>
          <div class="card">
            <div class="card-inner card-inner-xl">
              <div class="entry">
                <h3>Artikel 1 Definities</h3>
                <p>In deze algemene voorwaarden worden de volgende definities gebruikt, zowel in enkelvoud als in meervoud.</p>
                <p>Afnemer: de onderneming waarmee We/Assist Hypotheekspecialisten een Overeenkomst is aangegaan;</p>
                <p>Overeenkomst: de overeenkomst tussen de Afnemer en We/Assist Hypotheekspecialisten met betrekking tot levering van Leadgegevens;</p>
                <p>Algemene Voorwaarden: deze algemene voorwaarden, ongeacht de vorm waarin deze algemene voorwaarden aan de Afnemer worden verstrekt;</p>
                <p>Lead: een persoon die bij We/Assist Hypotheekspecialisten een verzoek heeft gedaan om te worden gecontacteerd door een hypotheekadviseur;</p>
                <p>We/Assist Hypotheekspecialisten: WE/ASSIST HYPOTHEEKSPECIALISTEN;</p>
                <h3>Artikel 2 Algemene voorwaarden</h3>
                <p><b>2.1</b> De Algemene Voorwaarden hebben betrekking op en maken deel uit van alle aanbiedingen, offertes, overeenkomsten en andere rechtsbetrekkingen, hetzij mondeling, schriftelijk, elektronisch of in enige andere vorm, met betrekking tot leadafname bij We/Assist Hypotheekspecialisten.</p>
                <p>
                  <b>2.2</b> Een Overeenkomst komt tot stand zodra Afnemer een registratie voltooid binnen onze software-applicatie en deze wordt goedgekeurd. We/Assist Hypotheekspecialisten heeft het recht om de registratie van de Afnemer te weigeren, bijvoorbeeld wanneer de door de Afnemer verstrekte informatie onwaar, onnauwkeurig, onvolledig of niet actueel is.</p>
                <p>
                  <b>2.3</b> Afwijkingen van de Algemene Voorwaarden gelden alleen indien en voor zover deze uitdrukkelijk schriftelijk in de Overeenkomst zijn overeengekomen tussen We/Assist Hypotheekspecialisten en de Afnemer.
                </p>
                <p>
                  <b>2.4</b> We/Assist Hypotheekspecialisten wijst uitdrukkelijk de toepasselijkheid van eventuele algemene (inkoop) voorwaarden van de Afnemer af.
                </p>
                <p>
                  <b>2.5</b> We/Assist Hypotheekspecialisten heeft het recht deze Algemene Voorwaarden te allen tijde te wijzigen door dergelijke gewijzigde Algemene Voorwaarden aan de Afnemer te verstrekken of deze te publiceren op de Website. Het voortgezette gebruik van de dienstverlening zal aanvaarding door de Afnemer vormen om gebonden te zijn aan de gewijzigde Algemene Voorwaarden.
                </p>
                <h3>Artikel 3 Facturering en betaling</h3>
                <p>
                  <b>3.1</b> Alle prijzen en tarieven die door We/Assist Hypotheekspecialisten worden genoemd zijn in Euro, exclusief belasting over de toegevoegde waarde (BTW) tenzij uitdrukkelijk anders schriftelijk overeengekomen.
                </p>
                <p>
                  <b>3.2</b> Facturen worden wekelijks verzonden naar het e-mailadres van de Afnemer. Verzuim van We/Assist Hypotheekspecialisten om welke reden dan ook met betrekking tot het tijdig versturen van een factuur zal geen invloed hebben op de verplichting van de Afnemer om te betalen voor alle gegenereerde leads in overeenstemming met de Overeenkomst.
                </p>
                <p>
                  <b>3.3</b> Betaling geschiedt altijd via automatische incasso. Bij de registratie binnen onze software-applicatie dient Afnemer een digitale incassomachtiging (e-mandate) af te geven middels een iDeal verificatiebetaling van € 0,01.
                </p>
                <p>
                  <b>3.4</b>
                  Betaling geschiedt altijd via automatische incasso, tenzij anders schriftelijk overeengekomen, en zonder enige schuldvergelijking of opschorting uit welken hoofde dan ook, behoudens wanneer besloten is, middels arbitrage of een vonnis van een rechtbank, dat de Afnemer een tegenvordering heeft welke in aanmerking komt voor schuldvergelijking of opschorting. Bij niet tijdige betaling zal de leadverstrekking worden stopgezet.
                </p>
                <p>
                  <b>3.5</b> Indien de Afnemer een factuur niet binnen de betalingstermijn voldoet, zal de Afnemer automatisch in verzuim zijn, zonder dat een aanmaning of ingebrekestelling is vereist. Vanaf dit moment tot de dag van volledige betaling, zal er rente over het openstaande bedrag verschuldigd zijn tegen een rente van 1,5% per maand of indien minder, het hoogste wettelijk toegestane percentage. Alle bankkosten in verband met betaling komen voor rekening van de Afnemer.
                </p>
                <p>
                  <b>3.6</b> Indien de Afnemer in gebreke blijft met betrekking tot haar betalingsverplichting van de uitstaande factuur met rente, kan de Leverancier de zaak uitbesteden aan een advocaat of incassobureau. Alle kosten die door de Leverancier worden gemaakt ten aanzien van deze incassoprocedure, zowel gerechtelijk als buitengerechtelijk, komen voor rekening van de Afnemer. De buitengerechtelijke kosten worden vastgesteld op 15% van de openstaande factuur, met een minimum van 250 (tweehonderdvijftig) Euro.
                </p>
                <h3>Artikel 4 Verplichtingen afnemer</h3>
                <p>
                  <b>4.1</b>
                  Indien overeengekomen zal de Afnemer, op eigen kosten, tijdig alle teksten, afbeeldingen, logo’s of andere materialen nodig voor de uitvoering van de reclamecampagne, gebruik binnen de software-applicatie en/of het genereren van de leads verstrekken aan We/Assist Hypotheekspecialisten. De Afnemer erkent en aanvaardt dat deze materialen worden gebruikt in het kader van de Leadafname en gepubliceerd kunnen worden op de door We/Assist Hypotheekspecialisten aangewezen websites en social media. Afnemer verleent aan We/Assist Hypotheekspecialisten een niet-exclusieve, royaltyvrije, wereldwijde licentie om de materialen te gebruiken voor de uitvoering van de dienstverlening.
                </p>
                <p>
                  <b>4.2</b>
                  De Afnemer is aansprakelijk voor enige aanspraak of schade ten gevolge van of in verband met de door hem verstrekte materialen. Afnemer garandeert dat de materialen: (a) geen inbreuk maakt op enig auteursrecht, octrooi, handelsmerk, handelsgeheim of andere eigendomsrechten of rechten van publiciteit of privacy van een derde partij; (b) niet in strijd is met enige wet, wetgeving, verordening of regelgeving, met inbegrip van maar niet beperkt tot, wetten en voorschriften inzake misleidende reclame en oneerlijke mededinging; (c) niet lasterlijk, pornografisch of obsceen is; en (d) geen virussen, Trojaanse paarden, wormen, tijdbommen, cancelbots of andere gelijkaardige schadelijke of nadelige programmering bevat.
                </p>
                <p>
                  <b>4.3</b> De Afnemer verklaart en garandeert hierbij bovendien dat het product of de dienst waarvoor Leads worden gegenereerd niet illegaal is of het onderwerp is van een lopend onderzoek door plaatselijke of federale regelgevende of quasi-regulerende autoriteiten.
                </p>
                <h3>Artikel 5 Leadgegevens</h3>
                <p>
                  <b>5.1</b> We/Assist Hypotheekspecialisten zal de Leadgegevens aan de Afnemer verstrekken op de overeengekomen wijze en op de overeengekomen gegevensdrager. We/Assist Hypotheekspecialisten verleent aan Afnemer een niet-overdraagbaar, niet exclusief en beperkt recht tot gebruik van de Leadgegevens. Afnemer is uitsluitend gerechtigd om de Leadgegevens te gebruiken om per mail of telefonisch contact op te nemen met de Lead met het doel om eenmalig een offerte te mogen uitbrengen. In dit contact moet duidelijk worden verwezen naar de aanvraag die de Lead bij We/Assist Hypotheekspecialisten heeft gedaan door de website te benoemen waarop de Lead is binnengekomen. Afnemer heeft geen aanvullende rechten of verplichtingen ten aanzien van persoonsgegevens die zij van We/Assist Hypotheekspecialisten heeft verkregen.
                </p>
                <p>
                  <b>5.2</b> Afnemer garandeert dat hij bij het gebruik van de Leadgegevens zich zal houden aan de voorwaarden, beperkingen en bevoegdheden opgenomen in de Overeenkomst (inclusief de Algemene Voorwaarden), waaronder mede begrepen, doch niet uitsluitend, voorwaarden en beperkingen met betrekking tot het voor het gebruik overeengekomen doel. Contractant is niet gerechtigd de Leadgegevens buiten de overeengekomen voorwaarden en bevoegdheden geheel of gedeeltelijk openbaar te maken, te verveelvoudigen of voor enig ander doel te gebruiken, op welke wijze dan ook.
                </p>
                <p>
                  <b>5.3</b> Afnemer is ermee bekend en akkoord dat de Leadgegevens uitsluitend bestaan uit gegevens die door de Leads zelf zijn ingevuld. Uitsluitend indien schriftelijk overeengekomen, zal We/Assist Hypotheekspecialisten de Leadgegevens controleren op kwaliteit, juistheid, rechtmatigheid.
                </p>
                <p>
                  <b>5.4</b> Afnemer garandeert dat hij steeds de laatste versie van de Leadgegevens zal gebruiken en dat hij op verzoek van We/Assist Hypotheekspecialisten de door We/Assist Hypotheekspecialisten aangeduide gegevens onmiddellijk uit de door hem gebruikte database zal verwijderen en verwijderd zal houden en elk verder gebruik daarvan zal staken en gestaakt zal houden.
                </p>
                <h3>Artikel 6 Gezamenlijk verantwoordelijken</h3>
                <p>
                  <b>6.1</b> Met betrekking tot de Leadgegevens die We/Assist Hypotheekspecialisten aan Afnemer verstrekt hebben beide partijen een verwerkingsverantwoordelijkheid richting de Lead omdat beide partijen de doeleinden en middelen van de verwerking van persoonsgegevens van de Lead bepalen. Gelet op artikel 26 van de Algemene Verordening Gegevensbescherming (“AVG”) worden in deze Algemene Voorwaarden de wederzijdse verantwoordelijkheden voor de nakoming van de verplichtingen uit hoofde van de AVG en de wet bescherming persoonsgegevens (“Wbp”) vastgelegd, met name met betrekking tot de uitoefening van de rechten van de betrokkenen en het verstrekken van informatie.
                </p>
                <p>
                  <b>6.2</b> We/Assist Hypotheekspecialisten verwerkt uitsluitend persoonsgegevens in verband met de aanvragen die Leads invullen op de websites/landingspagina’s en applicaties van We/Assist Hypotheekspecialisten. De aanvragen worden samen met de persoonsgegevens verstrekt aan Afnemer.
                </p>
                <p>
                  <b>6.3</b> We/Assist Hypotheekspecialisten verstrekt uiterlijk bij de verkrijging van de persoonsgegevens actuele informatie aan de Leads over welke gegevens door We/Assist Hypotheekspecialisten worden verwerkt en voor welk doel. Het betreft in ieder geval de volgende informatie:
                </p>
                <ul>
                  <li>Identiteit en contactgegevens van We/Assist Hypotheekspecialisten;</li>
                  <li>Indien van toepassing, de contactgegevens van de functionaris gegevensbescherming van We/Assist Hypotheekspecialisten;</li>
                  <li>Verwerkingsdoeleinden waarvoor de gegevens worden verzameld en rechtsgronden voor verwerking;</li>
                  <li>De partijen die persoonsgegevens ontvangen;</li>
                  <li>De bewaartermijn;</li>
                  <li>Het recht van Leads op inzage, rectificatie en verwijderen van gegevens, recht van bezwaar en recht op overdraagbaarheid van gegevens;</li>
                  <li>Het recht van Leads om een klacht in te dienen bij de Autoriteit Persoonsgegevens;</li>
                  <li>De reden van de verstrekking van persoonsgegevens, of de Lead verplicht is persoonsgegevens te verstrekken en wat de mogelijke gevolgen zijn bij het achterwege blijven daarvan;</li>
                </ul>
                <p>
                  <b>6.4</b> Beide partijen zijn aanspreekpunt richting de Leads indien zij vragen hebben over het verwerken van persoonsgegevens of hun rechten voortvloeiend uit de AVG willen uitoefenen. Indien Leads bij één van de partijen het verzoek doen om hun (persoons)gegevens te blokkeren, in te zien of te verwijderen, zullen partijen het verzoek steeds terstond in behandeling nemen en uiterlijk binnen een maand de Lead laten weten of en zo ja hoe aan het verzoek gehoor is gegeven. Partijen zullen elkaar steeds terstond van dergelijke verzoeken op de hoogte stellen.
                </p>
                <h3>Artikel 7. Beveiliging en meldplicht</h3>
                <p>
                  <b>7.1</b> Partijen zullen ervoor zorgen dat hun verwerking van persoonsgegevens voldoet aan alle toepasselijke wet- en regelgeving waaronder begrepen de Wbp en de AVG en dat ten aanzien van de verwerking van de persoonsgegevens voldoende en passende technische en organisatorische maatregelen worden genomen om te zorgen voor beschikbaarheid, integriteit en vertrouwelijkheid van persoonsgegevens en om persoonsgegevens te beveiligen tegen toevallige of onwettige vernietiging, wijziging, verlies, toegang, ongeautoriseerde openbaarmaking of enig (andere) vorm van onrechtmatige verwerking, waaronder maatregelen voor beperkte toegang tot en gebruik van de persoonsgegevens.
                </p>
                <p>
                  <b>7.2</b> In het geval van een beveiligingslek en/of een datalek (waaronder wordt verstaan: iedere inbreuk op de beveiliging van persoonsgegevens (hetzij per ongeluk hetzij door onrechtmatige verwerking) die leidt tot de aanzienlijke kans op ernstige nadelige gevolgen dan wel ernstige nadelige gevolgen heeft voor de bescherming van persoonsgegevens, zullen partijen elkaar onmiddellijk, maar uiterlijk binnen 48 uur na kennisname, daarover informeren en indien nodig eveneens de Autoriteit Persoonsgegevens daarover informeren. Partijen zijn beide verantwoordelijk voor het melden naar de relevante autoriteiten.
                </p>
                <p>
                  <b>7.3</b> De Afnemer garandeert dat zijn gebruik van de Leadgegevens niet in strijd is met enige toepasselijke wet- en regelgeving, met inbegrip van, maar niet beperkt tot, wet- en regelgeving op het gebied van de verwerking van persoonsgegevens, e-mailmarketing en telemarketing (bijvoorbeeld zoals neergelegd in de Telecommunicatiewet, de Wbp of de AVG) of en ook niet anderszins onrechtmatig zal zijn.
                </p>
                <h3>Artikel 8 Diensten en disclaimer</h3>
                <p>
                  <b>8.1</b> We/Assist Hypotheekspecialisten zal haar best doen om haar dienstverlening overeenkomstig de overeengekomen specificaties te leveren, met uitsluiting van enige uitdrukkelijke of stilzwijgende garanties of toezeggingen, van welke aard dan ook, waaronder mede begrepen, maar niet beperkt tot, de uitsluiting van garanties ten aanzien van kwaliteit, kwantiteit of geschiktheid van de Leadgegevens.
                </p>
                <h3>Artikel 9 Aansprakelijkheidsbeperking</h3>
                <p>
                  <b>9.1</b> We/Assist Hypotheekspecialisten is niet aansprakelijk voor de door Afnemer geleden schade, tenzij deze schade is veroorzaakt door een aan We/Assist Hypotheekspecialisten toerekenbare tekortkoming in de nakoming van haar verplichtingen uit de Overeenkomst, in welk geval We/Assist Hypotheekspecialisten uitsluitend aansprakelijk is voor directe schade. Onder directe schade wordt uitsluitend verstaan: a) de kosten die de Afnemer redelijkerwijs heeft moeten maken om de schending van We/Assist Hypotheekspecialisten te verhelpen of te corrigeren om de prestatie van We/Assist Hypotheekspecialisten in overeenstemming met de Overeenkomst te maken; b) de redelijke kosten voor het langer operationeel houden van het oude systeem van de Afnemer, verminderd met eventuele besparingen; en c) redelijke kosten ter voorkoming of beperking van dergelijke schade en redelijke kosten voor het vaststellen van de oorzaak en de omvang van de schade. Iedere aansprakelijkheid is beperkt tot het bedrag dat in het kader van deze overeenkomst in het desbetreffende geval wordt uitbetaald door de beroepsaansprakelijkheid-verzekeraar, vermeerderd met het eigen risico dat op grond van de verzekering ten laste van WE/ASSIST Advocaten komt.
                </p>
                <p>
                  <b>9.2</b> In geen geval zal de totale aansprakelijkheid van We/Assist Hypotheekspecialisten hoger zijn dan het totale bedrag dat Afnemer voor dienstverlening heeft betaald. In het geval dat de Overeenkomst een looptijd van meer dan één jaar heeft, zal de vergoeding worden vastgesteld op het totaalbedrag van de vergoedingen bedongen voor het lopende jaar op het moment waarop de schending plaatsvindt. In geen geval zal de totale vergoeding meer dan duizend euro (EUR 1.000) bedragen voor één incident, waarbij een reeks incidenten zal worden beschouwd als één incident.
                </p>
                <p>
                  <b>9.3</b> In plaats van het betalen van een schadevergoeding mag We/Assist Hypotheekspecialisten, naar haar eigen keuze, een “goedmaak campagne” uitvoeren, mits de “goedmaak campagne” binnen een redelijke termijn na het ontstaan van de aansprakelijkheid kan worden uitgevoerd.
                </p>
                <h3>Artikel 10 Vrijwaring</h3>
                <p>
                  <b>10.3</b> De Afnemer vrijwaart We/Assist Hypotheekspecialisten, haar directeuren, functionarissen en vertegenwoordigers, tegen aanspraken van derden (waaronder mede doch niet uitsluitend begrepen aanspraken van Leads), maatregelen van toezichthoudende instanties (waaronder bijvoorbeeld begrepen boetes van ACM of van de Autoriteit persoonsgegevens) en overige schade en kosten (inclusief redelijke juridische kosten) die het gevolg zijn van het niet-nakomen van de verplichtingen van Afnemer uit de Overeenkomst (inclusief deze Algemene Voorwaarden) dan wel uit diens handelen in strijd met de van toepassing zijnde wet- en regelgeving.
                </p>
                <h3>Artikel 11 Beëindiging</h3>
                <p>
                  <b>11.1</b> De overeenkomst wordt aangegaan voor de in de Overeenkomst overeengekomen periode, bij gebreke waarvan de overeenkomst geldt voor onbepaalde tijd. Indien de overeenkomst wordt aangegaan voor onbepaalde tijd kan deze schriftelijk door de Afnemer worden opgezegd met inachtneming van een opzegtermijn van drie (3) kalendermaanden en door We/Assist Hypotheekspecialisten zonder inachtneming van een opzegtermijn.
                </p>
                <p>
                  <b>11.2</b> Onverminderd alle andere rechten, zijn partijen gerechtigd de Overeenkomst geheel of gedeeltelijk met onmiddellijke ingang te beëindigen, zonder nadere ingebrekestelling of rechterlijke interventie vereist is in het geval de wederpartij surseance van betaling heeft aangevraagd of dit is verleend; of failliet is verklaard of faillissement is aangevraagd.
                </p>
                <p>
                  <b>11.3</b> Onverminderd haar overige rechten, zal We/Assist Hypotheekspecialisten de Overeenkomst geheel of gedeeltelijk met onmiddellijke ingang kunnen beëindigen, ontbinden of opschorten indien de Afnemer in gebreke blijft wat betreft haar verplichtingen onder de Overeenkomst (inclusief deze Algemene Voorwaarden).
                </p>
                <p>
                  <b>11.4</b> In geen geval zal de beëindiging of ontbinding van de Overeenkomst de Afnemer ontslaan van enige betalingsverplichting ten aanzien van de diensten en Leadgegevens die reeds zijn geleverd door We/Assist Hypotheekspecialisten. Bedragen die We/Assist Hypotheekspecialisten heeft gefactureerd voorafgaand aan de beëindiging in verband met de diensten of de Leadgegevens die zij reeds heeft geleverd, worden onmiddellijk opeisbaar op het tijdstip van de beëindiging.
                </p>
                <p>
                  <b>11.5</b> De bepalingen die naar hun aard bestemd zijn om de beëindiging van de Overeenkomst te overleven, blijven van toepassing na de beëindiging.
                </p>
                <h3>Artikel 12 Vertrouwelijke informatie</h3>
                <p>
                  <b>12.1</b> “Vertrouwelijke informatie” zal alle mondelinge of schriftelijke informatie zijn die als vertrouwelijk wordt aangemerkt en door de ene partij aan de andere wordt verstrekt.
                </p>
                <p>
                  <b>12.2</b> De Afnemer noch We/Assist Hypotheekspecialisten zal de vertrouwelijke informatie van de wederpartij gebruiken of openbaar maken voor enig ander doel dan het doel van de Overeenkomst, tenzij een dergelijke openbaarmaking of gebruik is toegestaan met schriftelijke toestemming van de andere partij.
                </p>
                <p>
                  <b>12.3</b> Onverminderd de overige bepalingen van deze Overeenkomst kan elke partij de vertrouwelijke informatie van de wederpartij openbaar maken zoals vereist onder toepasselijke wetgeving, maar pas na vijf (5) werkdagen na voorafgaande schriftelijke kennisgeving aan de andere partij van dergelijke vereiste openbaarmaking.
                </p>
                <p>
                  <b>12.4</b> De Afnemer erkent dat We/Assist Hypotheekspecialisten de advertenties plaatst op websites van derden en sub-netwerken binnen het We/Assist Hypotheekspecialisten netwerk. De identiteit van de uitgevers in het We/Assist Hypotheekspecialisten netwerk wordt geacht vertrouwelijke informatie van We/Assist Hypotheekspecialisten te zijn en de Afnemer stemt ermee in om dergelijke merkgebonden informatie niet te gebruiken of openbaar te maken anders dan in verband met het inschakelen van en werken met We/Assist Hypotheekspecialisten krachtens deze Overeenkomst.
                </p>
                <p>
                  <b>12.5</b> Na beëindiging of opzegging van deze Overeenkomst om welke reden dan ook, of op verzoek van één der partijen, zullen de vertrouwelijke informatie van de verzoekende partij, samen met eventuele kopieën daarvan, aan deze partij worden geretourneerd of aanwijsbaar worden vernietigd, met de uitzondering dat de partijen de elektronische versies van alle vertrouwelijke informatie van de andere partij uitsluitend voor archiveringsdoeleinden of juridische doeleinden mogen behouden. De vertrouwelijke informatie van de Afnemer is en blijft het eigendom van de Afnemer en de vertrouwelijke informatie van We/Assist Hypotheekspecialisten is en blijft het eigendom van We/Assist Hypotheekspecialisten.
                </p>
                <h3>Artikel 13 Overig</h3>
                <p>
                  <b>13.1</b> De Overeenkomst, inclusief deze Algemene Voorwaarden, vormt de volledige overeenkomst tussen de partijen en vervangt alle eerdere mondelinge of schriftelijke overeenkomsten of afspraken tussen de partijen met betrekking tot het onderwerp van deze Overeenkomst.
                </p>
                <p>
                  <b>13.2</b> De Overeenkomst wordt beheerst door en zal worden geïnterpreteerd overeenkomstig de wetten van Nederland. De Afnemer en We/Assist Hypotheekspecialisten komen overeen zich te onderwerpen aan de exclusieve jurisdictie van de rechtbanken in Nederland.
                </p>
                <p>
                  <b>13.3</b> Indien enige bepaling van deze Overeenkomst ongeldig of niet- afdwingbaar blijkt te zijn om welke reden dan ook, blijven de overige bepalingen onverminderd van kracht blijft zonder beperkingen of ongeldigheid.
                </p>
                <p>
                  <b>13.4</b> De Afnemer mag de Overeenkomst niet overdragen zonder de voorafgaande schriftelijke toestemming van We/Assist Hypotheekspecialisten. We/Assist Hypotheekspecialisten is vrij om de Overeenkomst over te dragen, hetzij geheel hetzij in scheidbare deel, aan (a) elke partner of dochteronderneming (te allen tijde en zonder aankondiging) of (b) in verband met enig bedrijfsreorganisatie, inkoop van voorraad, fusie of verkoop van alle of vrijwel alle activa en het bedrijf in verband met het onderwerp van de Overeenkomst.
                </p>
                <p>Wijchen, Januari 2022</p>
              </div>
            </div>
          </div>
        </block-content>
      </div>
    </main-block>
  </page-template>
</template>

<script>
import {useIonRouter} from "@ionic/vue"
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import BrandLogo from "@core/components/logo/BrandLogo";
import NkButton from "@core/components/button/NkButton";
import PageTemplate from "@core/layouts/page/PageTemplate";
export default {
  name: "TOS",
  components:{PageTemplate, NkButton, BrandLogo, BlockContent, MainBlock},
  setup(){
    const ionRouter = useIonRouter()
    return{
      ionRouter,
    }
  }
}
</script>

<style scoped>

</style>