<template>
  <router-link :to="{name: 'Home'}" class="logo-link">
    <img class="logo-light logo-img logo-img-lg" :src="appLogoLight" :srcset="require('@/assets/images/logos/logo-light2x.png')+' 2x'" alt="logo">
    <img class="logo-dark logo-img logo-img-lg" :src="appLogo" :srcset="require('@/assets/images/logos/logo2x.png')+' 2x'" alt="logo-dark">
  </router-link>
</template>

<script>
import {$themeConfig} from "../../../../themeConfig";

const { appLogo, appLogoLight} = $themeConfig.app;
export default {
  setup(){
    return {
      appLogo,
      appLogoLight,
    }
  }
}
</script>
