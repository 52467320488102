<template>
  <ion-toolbar id="app-header-toolbar">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-item lines="none" style="--background: transparent;">
      <brand-logo></brand-logo>
    </ion-item>
  </ion-toolbar>
</template>

<script>
import BrandLogo from "@core/components/logo/BrandLogo";
import {IonBackButton, IonButtons, IonItem, IonToolbar} from "@ionic/vue";

export default {
  name: "NavBarGuests",
  components: {BrandLogo, IonBackButton, IonButtons, IonItem, IonToolbar}
}
</script>
