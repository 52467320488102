<template>
  <nav-bar-guests v-if="!userAuth"></nav-bar-guests>
  <ion-toolbar id="app-header-toolbar" v-else>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <img class="logo-img" :src="require('@/assets/images/logos/logo-small.png')" :srcset="require('@/assets/images/logos/logo-small2x.png')+' 2x'" alt="logo-dark">
    </ion-title>
    <ion-buttons slot="end">
      <ion-button v-on:click="toggleDarkMode">
        <nio-icon :icon="darkMode ? 'ni-moon-fill' : 'ni-moon'" size="2rem"></nio-icon>
      </ion-button>
      <ion-button v-on:click="$router.push({name: 'Notifications'})">
        <div class="position-relative d-inline-flex" v-if="store.state.notifications.unread_count > 0">
          <ion-badge color="danger" class="position-absolute">{{ store.state.notifications.unread_count}}</ion-badge>
          <nio-icon icon="ni-bell" size="2rem"></nio-icon>
        </div>
        <nio-icon icon="ni-bell" v-else size="2rem"></nio-icon>
      </ion-button>
      <ion-button v-on:click="$router.push({ name: navBarUserDropdownLinks.account_setting.route })">
        <div class="user-toggle">
          <div class="user-avatar sm">
            <img v-if="currentUserData.dp" alt="DP" :src="currentUserData.dp"/>
            <nio-icon v-else icon="ni-user-alt"></nio-icon>
          </div>
        </div>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</template>

<script>
import {IonBadge, IonButton, IonButtons, IonMenuButton, IonTitle, IonToolbar} from '@ionic/vue'
import storeHelper from '@/store/helper'
import {computed, ref} from "vue";
import {useStore} from "vuex";
import useCommonFunc from '@core/comp-functions/common';
import NavBarGuests from "@core/layouts/app-nav-bar/mobile/NavBarGuests";

export default {
  components:{
    NavBarGuests,
    IonBadge,
    IonButton,
    IonButtons,
    IonMenuButton,
    IonTitle,
    IonToolbar,
  },
  props:{
    noLeftNavRoutes: {
      type: Array,
      default: ()=> [],
    },
  },
  setup(){
    const{currentUserData} = storeHelper()
    const{navBarUserDropdownLinks} = useCommonFunc()
    const store = useStore()
    let darkMode = ref(store.state.appConfig.dark_mode)
    const userAuth = computed(()=>{
      return store.state.auth.is_logged_in
    })
    const toggleDarkMode = () => {
      store.state.appConfig.dark_mode = !store.state.appConfig.dark_mode
      darkMode.value = store.state.appConfig.dark_mode
      localStorage.setItem('dark_mode', (darkMode.value ? '1' : '0'))
    }

    return{
      currentUserData,
      darkMode,
      navBarUserDropdownLinks,
      store,
      toggleDarkMode,
      userAuth,
    }
  }
}
</script>
